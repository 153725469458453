<form role="form" [formGroup]="formGroup" *ngIf="documentsTypeList != null">
  <div class="form-group mdc-layout-grid">
    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
  
        <mat-form-field appearance="outline">
          <mat-label>Tipo de documento</mat-label>
          <mat-select formControlName="type_document">
            <mat-option *ngFor="let item of documentsTypeList" [value]="item">
              <span>{{ item.name }}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.get('type_document').hasError('required')">
            {{errors.required}}
          </mat-error>
        </mat-form-field>

      </div>
    </div>

    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
    
        <mat-form-field appearance="outline" class="pointer">
          <mat-label>Documento</mat-label>
          <input matInput (click)="uploadAction()" formControlName="title" [readonly]="true">
          <mat-error *ngIf="formGroup.get('title').hasError('required')">
            {{errors.required}}
          </mat-error>
        </mat-form-field>
      
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone margin-input">

        <button mat-raised-button color="primary" (click)="uploadAction()">Seleccionar documento <mat-icon>cloud_upload</mat-icon></button>
        <input class="hidden" (change)="fileUpload($event)" #uploadFile type="file">
        <mat-error *ngIf="fileError">
          {{fileError}}
        </mat-error>

      </div>
    </div>

    <div class="mdc-layout-grid__inner">
      <div class="button-group mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
        <a mat-button color="primary" (click)="cancel()" >Cancelar</a>
        <button type="button" (click)="submit()" mat-button color="primary" [disabled]="!formGroup.valid">Guardar</button>
      </div>
    </div>
  </div>
</form>
