import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppCommunicationService {

  private _snackErrorMessageSource = new Subject<string>();

  snackErrorMessage$ = this._snackErrorMessageSource.asObservable();

  sendSnackErrorMessage(message: string) {
    if (message) {
      console.log('Sending error message:', message);
      this._snackErrorMessageSource.next(message);
    }
  }
}
