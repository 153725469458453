export class ObjectUtils {
  public static deepCopy(o): any {
    let copy = o;

    if (o && typeof o === 'object') {

      switch (Object.prototype.toString.call(o)) {
        case '[object Array]':
          copy = [];
          break;
        case '[object Date]':
          copy = new Date(o.getTime());
          break;
        default:
          copy = {};
          break;
      }

      for (const k in o) {
        if (k in o) {
          copy[k] = this.deepCopy(o[k]);
        }
      }
    }

    return copy;
  }
}
