<div [formGroup]="form">
  <!--<label [attr.for]="question.key">{{question.label}}</label>-->

  <div [ngSwitch]="question.controlType">
    <mat-form-field appearance="outline" *ngSwitchCase="'textbox'">
      <mat-label>{{question.label}}</mat-label>
      <input [type]="question.type" matInput [formControlName]="question.key" [id]="question.key">
      <mat-error *ngIf="!isValid">{{question.label}} is required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngSwitchCase="'date'">
      <mat-label>{{question.label}}</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" [formControlName]="question.key">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <select [id]="question.key" *ngSwitchCase="'dropdown'" [formControlName]="question.key">
      <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
    </select>

  </div>

  <!--<div class="errorMessage" *ngIf="!isValid">{{question.label}} is required</div>-->
</div>
