import { Component, OnInit, ViewChild, ElementRef, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';


import { mergeMap } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Texts } from '../../../gestor-academico/classes/texts';
import { ApiService } from '../../../core/api/api.service';

@Component({
  selector: 'ad-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss']
})
export class DocumentFormComponent implements OnInit {

  formGroup: UntypedFormGroup;
  errors = Texts.errors;
  filteredDocuments: Observable<object>;
  @ViewChild('uploadFile') uploadFile: ElementRef;
  file: File;
  fileError: string;
  documentsTypeList: any[];

  constructor(
    public dialogRef: MatDialogRef<DocumentFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private _fb: UntypedFormBuilder,
    private _apiService: ApiService
  ) { }

  ngOnInit() {

    this.formGroup = this._fb.group({
      title: ['', Validators.required],
      type_document: ['', Validators.required],
      document: [''],
    });


    this._apiService.getResourceService('documentsType').listFilter('?limit=0&section_code='+this.data.sectionFilter)
      .subscribe(data => {
        this.documentsTypeList = data.objects;
      });
  }

  submit() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        title: this.formGroup.get('title').value,
        type_document_id: this.formGroup.get('type_document').value.id,
        file: this.file
      });
    }
  }

  displayFn(val: any) {
    return val ? val.name : val;
  }
  uploadAction() {
    if (this.formGroup.get('type_document').value) {
      this.uploadFile.nativeElement.click();
    }
  }
  fileUpload(event: any): void {

    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.file = event.target.files[0];
      this.formGroup.patchValue({
        title: file.name
      });
    }
  }
  upload(file: File, method: string) {
    /* this.fileError = '';
    if (!file || !FileUtils.fileIsValid(file.name)) {
      this.fileError = FileUtils.getErrorMsg();
      return;
    }
    if (this.router.url.indexOf('teacher') !== -1) {
      this._apiService.getResourceService('teacherDocuments')
        .uploadAdjuntos({
          teacher_id: this.data,
          title: this.formGroup.get('document').value || file.name,
          type_document_id: this.formGroup.get('type_document').value.id
        }, file, method)
        .subscribe(res => {
          res.name = res.type_document_name;
          this.dialogRef.close(res);
          // this.router.navigate(['../../'], { relativeTo: this.route });
        }
        );
    } else {
      this._apiService.getResourceService('studentDocuments')
        .uploadAdjuntos({
          student_id: this.data,
          title: this.formGroup.get('document').value || file.name,
          type_document_id: this.formGroup.get('type_document').value.id
        }, file, method)
        .subscribe(res => {
          res.name = res.type_document_name;
          this.dialogRef.close(res);
          // this.router.navigate(['../../'], { relativeTo: this.route });
        }
        );
    } */
  }
  cancel() {
    this.dialogRef.close();
  }

}
