<div class="container" *ngIf="score == 0">
  <ng-container>
    <i class="a-icon a-icon-star a-star-0-5">&nbsp;</i>
  </ng-container>
</div>

<div class="container" *ngIf="score > 0 && score < 1">
  <ng-container>
    <i class="a-icon a-icon-star a-star-0_5-5">&nbsp;</i>
  </ng-container>
</div>

<div class="container" *ngIf="score == 1">
<ng-container>
  <i class="a-icon a-icon-star a-star-1-5">&nbsp;</i>
</ng-container>
</div>

<div class="container" *ngIf="score > 1 && score < 2">
<ng-container>
  <i class="a-icon a-icon-star a-star-1_5-5">&nbsp;</i>
</ng-container>
</div>

<div class="container" *ngIf="score == 2">
<ng-container>
  <i class="a-icon a-icon-star a-star-2-5">&nbsp;</i>
</ng-container>
</div>

<div class="container" *ngIf="score > 2 && score < 3">
<ng-container>
  <i class="a-icon a-icon-star a-star-2_5-5">&nbsp;</i>
</ng-container>
</div>

<div class="container" *ngIf="score == 3">
<ng-container>
  <i class="a-icon a-icon-star a-star-3-5">&nbsp;</i>
</ng-container>
</div>

<div class="container" *ngIf="score > 3 && score < 4">
<ng-container>
  <i class="a-icon a-icon-star a-star-3_5-5">&nbsp;</i>
</ng-container>
</div>

<div class="container" *ngIf="score == 4">
<ng-container>
  <i class="a-icon a-icon-star a-star-4-5">&nbsp;</i>
</ng-container>
</div>

<div class="container" *ngIf="score > 4 && score < 5">
<ng-container>
  <i class="a-icon a-icon-star a-star-4_5-5">&nbsp;</i>
</ng-container>
</div>

<div class="container" *ngIf="score == 5">
<ng-container>
  <i class="a-icon a-icon-star a-star-5-5">&nbsp;</i>
</ng-container>
</div>