<fieldset>
    <legend>Documentos</legend>

    <mat-table #table [dataSource]="documentsDatasource" matSort matSortActive="{{sortColumn}}" matSortDirection="{{sortDirection}}">

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Documento </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.title}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de documento </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="uploaded_at_str">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de subida </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.uploaded_at_str}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <a class="pointer" target="_blank" (click)="previewDocument(row.id)"><mat-icon>visibility</mat-icon></a>
          <!-- <a class="pointer"  target="_blank" (click)= "downloadDocument(row.id)"><mat-icon>visibility</mat-icon></a> -->
          <a class="pointer" (click)= "downloadDocument(row.id, $event)"><mat-icon>cloud_download</mat-icon></a>
          <!-- BORRADO DE DOCUMENTO -->
          <ng-container *ngIf="permissions.canDelete">
            <a class="pointer" [ngClass]="{'disabled':!editable}" (click)="confirmDeleteDocument(row.id)" ><mat-icon>delete_forever</mat-icon></a>
          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="documentColumns"></mat-header-row>
      <mat-row class="pointer" (click)="previewDocument(row.id)" *matRowDef="let row; columns: documentColumns;"></mat-row>
    </mat-table>

    <input class="hidden float-right" #uploadFile type="file">

    <div class="button-group" *ngIf="permissions.canAdd">
      <button type="button" mat-button color="primary" (click)="newDocument()" [disabled]="!editable">Añadir documento</button>
    </div>

  </fieldset>
