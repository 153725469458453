import { Component, OnInit, Input, Output, EventEmitter, DoCheck, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TableDataSource } from '../../gestor-academico/classes/table-data-source';
import { ArraySort } from '../../gestor-academico/classes/array-sort';
import { DeleteConfirmDialogComponent } from '../delete-confirm-dialog/delete-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ModalFormComponent, DataModalForm } from '../modal-form/modal-form.component';
import { DocumentFormComponent } from './document-form/document-form.component';
import { ApiService } from '../../core/api/api.service';
import { FileUtils } from '../../gestor-academico/classes/file-utils';

@Component({
  selector: 'ad-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, DoCheck {

  @Input()
  documents: any[];

  @Input()
  idField: string;

  @Input()
  sectionFilter: string;

  @Input()
  endPointDownload: string;

  @Input()
  id: number;

  documentsDatasource;
  documentColumns = ['title', 'name', 'uploaded_at_str','actions'];

  @Output()
  onDownload: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  editable: false;

  @Input()
  permissions: {'canAdd': false, 'canDelete': false, 'canEdit': false};

  @Output()
  onDelete: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter<any>();

  @Input() sortableColumns: string[];

  sortColumn;
  sortDirection;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  private _oldDocuments;



  constructor(
    public dialog: MatDialog,
    private _apiService: ApiService,
  ) { }

  ngOnInit() {
    this.sortColumn = this.sortableColumns[0];
    this.sortDirection = 'asc';
    this.documentsDatasource = new TableDataSource(of({objects: this.documents}), null, this.sort);
    this._oldDocuments = JSON.parse(JSON.stringify(this.documentColumns));
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(data => this.changeSort(data));
    this.sort.direction = this.sortDirection;
    this.sort.disableClear = true;
    if (this.documents) {
      ArraySort.orderByArray(this.documents, this.sortColumn, this.sortDirection);
      this.documentsDatasource = new TableDataSource(of({objects: this.documents}), null, this.sort);
    } else {
      this.documentsDatasource = new TableDataSource(of({objects: '' }), null, this.sort);
    }
  }

  ngDoCheck() {
    if (this.documents !== this._oldDocuments) {
      this.documentsDatasource = new TableDataSource(of({objects: this.documents}));
      this._oldDocuments = JSON.parse(JSON.stringify(this.documentColumns));
    }
  }

  // download(file) {
  //   this.onDownload.emit(file);
  // }

  downloadDocument(item, event: Event) {
    event.stopPropagation();
    this._apiService.getResourceService(this.endPointDownload)
      .getFile(`${item}/download`)
      .subscribe(res => {
        const filename = res.headers.get('Content-Disposition');
        FileUtils.getFile(res, filename);
    });
  }

  previewDocument(documentId: number) {
    this.dialog.open(ModalFormComponent, {
      width: '600px',
      data: {
        isPreview: true,
        documentId: documentId,
        endpoint: this.endPointDownload,
        modalTitle: 'Previsualización del documento'
      }
    });
  }

  confirmDeleteDocument(id: number): void {
    if (!this.editable) {
      return;
    }
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: { title: 'Eliminar documento', text: '¿Está seguro que desea eliminar este documento?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'delete') {
        this.onDelete.emit(id);
      }
    });
  }

  newDocument() {
    const dialogRef = this.dialog.open(DocumentFormComponent, {
      data: {
        id: this.id,
        idField: this.idField,
        sectionFilter: this.sectionFilter
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result[this.idField] = this.id;
        this.onSubmit.emit(result);
        //this.objeto['documents'].push(result);
        //this['documents'] = new TableDataSource(of({objects: this.objeto['documents']}));
      }
    });
  }

  changeSort(e) {
    this.sortColumn = this.sortableColumns[this.documentColumns.indexOf(e.active)];
    this.sortDirection = e.direction;
    ArraySort.orderByArray(this.documents, e.active,  e.direction);
    this.documentsDatasource = new TableDataSource(of({objects: this.documents}), null, this.sort);
  }

}
