import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iterable'
})
export class IterablePipe implements PipeTransform {

  transform(value: object, args?: any): any {
    return Object.keys(value);
  }

}
