import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixSelect'
})
export class FixSelectPipe implements PipeTransform {

  transform(value: any, list: any[]): any {
    if (list && list.length > 0) {
      if (typeof list[0].id === 'number') {
        return Number(value);
      }
      if (typeof list[0].id === 'string') {
        return String(value);
      }
    }
    return value;
  }

}
