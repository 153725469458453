import { ObjectUtils } from './../../gestor-academico/classes/object-utils';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TableDataSource } from '../../gestor-academico/classes/table-data-source';
import { Observable, of } from 'rxjs';
import { ModalFormComponent, DataModalForm } from '../modal-form/modal-form.component';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmDialogComponent } from '../delete-confirm-dialog/delete-confirm-dialog.component';
import { ApiService } from '../../core/api/api.service';

@Component({
  selector: 'ad-form-annotation-list',
  templateUrl: './form-annotation-list.component.html',
  styleUrls: ['./form-annotation-list.component.scss']
})
export class FormAnnotationListComponent implements OnInit, OnChanges {

  @Input() displayedColumns = { name: 'Nombre', actions: 'Acciones', delete: 'Eliminar' };
  @Input() displayedAdminColumns = { name: 'Nombre', actions: 'Acciones', delete: 'Eliminar' };
  @Input() data: any[];
  @Input() addText = 'Añadir';
  @Input() listTitle;
  @Input() functionDetail: (id: string|number) => void;
  @Input() formConfig: DataModalForm;
  @Input() edit = true;
  @Input() permissions: any[];
  columns: string[];
  adminColumns: string[];
  activeList: TableDataSource;
  deleteList: TableDataSource;
  activeAnnotations: any[];
  deleteAnnotations: any[];
  showDeleteAnnotation = false;
  canDelete = false;
  canList = false;
  localformConfig: DataModalForm;
  private _localFormConfig: DataModalForm;
  constructor(
    public dialog: MatDialog,
    private _apiService: ApiService,
  ) { 
    this._localFormConfig = ObjectUtils.deepCopy(this.formConfig);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.edit && this.formConfig) {
      this.formConfig.edit = changes.edit.currentValue;
      this._resetConfig();
    }
  }
  ngOnInit() {
    // Comprobar si se puede añadir
    const admin_permission = JSON.parse(sessionStorage.getItem('permisos'));
    if (admin_permission['secc_annotations_adminstration']) {
      if (admin_permission['secc_annotations_adminstration']['per_consultar']) {
        this.canList = true;
      }
      if (admin_permission['secc_annotations_adminstration']['per_eliminar']) {
        this.canDelete = true;
      }
    }
    this._resetConfig();

    this.formConfig.edit = this.edit;
    if (!this.listTitle || !this.data || !this.formConfig) {
      throw new Error('faltan datos de entrada');
    }
    this.columns = Object.keys(this.displayedColumns);
    this.adminColumns = Object.keys(this.displayedAdminColumns);
    this._generateList();
  }
  navigateDetail(data) {
    this._updateValue(data);
    this._showModal();
    this._localFormConfig.id = data.id;
  }
  newItem() {
    if (!this.edit) {
      return;
    }
    this._localFormConfig.id = null;
    this._showModal();
  }
  private _showModal() {
    const dialogRef = this.dialog.open(ModalFormComponent, {
      data: this._localFormConfig
    });

    dialogRef.afterClosed().subscribe(result => {
      this._resetConfig();
      if (result instanceof Object) {
        for (let index = 0; index < this.data.length; index++) {
          const element = this.data[index];
          if (element && element.id == result.id) {
            this.data.splice(index, 1);
          }
        }
        this.data.push(result);
        this._generateList();
        //this.list = new TableDataSource(of({objects: this.data}));
      }
    });
  }
  private _updateValue(data) {
    this._localFormConfig.formControls.forEach(control => {
      if (data.hasOwnProperty(control.name)) {
        control.value = data[control.name];
      }
    });
  }

  confirmDelete(id: string|number, event: Event): void {
    if (!this.edit) {
      event.stopPropagation();
      return;
    }
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: { title: 'Eliminar', text: '¿Está seguro que desea eliminar este registro?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'delete') {
        this.deleteConfig(id);
      }
    });
  }

  deleteConfig(id: string|number) {
    const api = this._apiService.getResourceService(this._localFormConfig.endpoint);
    const data = {'deleted': true};
    api.update(
      data, id
    ).subscribe(
      success => {
        for (let index = 0; index < this.data.length; index++) {
          const element = this.data[index];
          if (element && element.id == id) {
            element.deleted = true;
            this._generateList();
          }
        }
      }
    );
  }
  confirmRealDelete(id: string|number, event: Event): void {
    if (!this.edit) {
      event.stopPropagation();
      return;
    }
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: { title: 'Eliminar', text: '¿Está seguro que desea eliminar este registro?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'delete') {
        this.deleteRealConfig(id);
      }
    });
  }
  deleteRealConfig(id: string|number) {
    const api = this._apiService.getResourceService(this._localFormConfig.endpoint + 'Delete');
    api.delete(id).subscribe(
      success => {
        for (let index = 0; index < this.data.length; index++) {
          const element = this.data[index];
          if (element && element.id == id) {
            this.data.splice(index, 1);
          }
        }
        this._generateList();
        //this.list = new TableDataSource(of({objects: this.data}));

      }
    );
  }
  private _resetConfig() {
    this._localFormConfig = ObjectUtils.deepCopy(this.formConfig);
  }

  private _generateList() {
    this.activeAnnotations = this.data.filter( annotation => annotation.deleted === false );
    this.deleteAnnotations = this.data.filter( annotation => annotation.deleted === true );
    this.activeList = new TableDataSource(of({objects:  this.activeAnnotations}));
    this.deleteList = new TableDataSource(of({objects:  this.deleteAnnotations}));
    if (this.deleteAnnotations.length >0) {
      this.showDeleteAnnotation = true;
    }
  }
}
