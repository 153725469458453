<form [formGroup]="internalForm">
  <mat-form-field appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <input
      #inputString
      matInput
      [readonly]="disabled"
      [placeholder]="placeholder"
      formControlName="inputString"
      (keyup)="cambioFechaString($event)"
      >
    <input
      style="display: none"
      matInput
      [matDatepicker]="picker"
      [placeholder]="placeholder"
      formControlName="input"
      [readonly]="disabled"
      (dateChange)="addEvent($event)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker [disabled]="disabled" #picker></mat-datepicker>
    <mat-error *ngIf="internalForm.get('input').hasError('required')">
      {{errors.required}}
    </mat-error>
  </mat-form-field>
</form>
