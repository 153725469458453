import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResource } from './api-resource';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AppCommunicationService } from '../../app-communication.service';

@Injectable()
export class ApiService {
  private _baseApi: string;
  private _token: string;

  constructor(
    private http: HttpClient,
    private _router: Router,
    private _appCommunicationService: AppCommunicationService
  ) {
    this._baseApi = environment.baseApi;
    if (sessionStorage.getItem('token')) {
      this._token = sessionStorage.getItem('token');
    }
  }

  public init(baseApi: string, token: string): void {
    this._baseApi = baseApi;
    this._token = token;
  }

  getResourceService(serviceName: string) {
    return new ApiResource(
      this._baseApi,
      serviceName,
      this.http,
      this._token,
      this._router,
      this._appCommunicationService
    );
  }

  getResourceExternalService(): Observable<any> {
    return this.http.get('https://institutofranklin.net/jsonapi/views/eventos_listado_de_eventos/eventos_api?fields[commerce_product--eventos]=title,path,field_subtitle,field_dates,body');
    // return this.http.get('https://institutofranklin.net/jsonapi/views/eventos_listado_de_eventos/eventos_api?fields[commerce_product--eventos]=title,path');
  }

  getResourceExternalBooksService(): Observable<any> {
    return this.http.get('https://institutofranklin.net/jsonapi/views/publicaciones_api_libros_revistas/librosapi?fields[commerce_product--numeros_de_revistas]=title,path&fields[commerce_product--libros]=title,path');
  }
}
