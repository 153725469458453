import { Component, Input} from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
  selector: 'ad-score-rating',
  templateUrl: './score-rating.component.html',
  styleUrls: ['./score-rating.component.scss']
})
export class ScoreRatingComponent implements OnInit{

  @Input() score: any = '';

  ngOnInit() {
    if (typeof this.score === 'string') {
      this.score = parseFloat(this.score);
    }
  }
}
