import { IterableModule } from './../iterable/iterable.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormAnnotationListComponent } from './form-annotation-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalFormModule } from '../modal-form/modal-form.module';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    IterableModule,
    MatButtonModule,
    MatDialogModule,
    ModalFormModule
  ],
  declarations: [FormAnnotationListComponent],
  exports: [FormAnnotationListComponent]
})
export class FormAnnotationListModule { }
