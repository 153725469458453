import { ObjectUtils } from './../../gestor-academico/classes/object-utils';
import { Component, OnInit, Input, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { TableDataSource } from '../../gestor-academico/classes/table-data-source';
import { ArraySort } from '../../gestor-academico/classes/array-sort';
import { Observable, of } from 'rxjs';
import { ModalFormComponent, DataModalForm } from '../modal-form/modal-form.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { DeleteConfirmDialogComponent } from '../delete-confirm-dialog/delete-confirm-dialog.component';
import { ApiService } from '../../core/api/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ad-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss']
})
export class FormListComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() displayedColumns = { name: 'Nombre', actions: 'Acciones', delete: 'Eliminar' };
  @Input() data: any[];
  @Input() addText = 'Añadir';
  @Input() listTitle;
  @Input() functionDetail: (id: string|number) => void;
  @Input() formConfig: DataModalForm;
  @Input() edit = true;
  @Input() exApi = false;
  @Input() permissions: any[];
  columns: string[];
  list: TableDataSource;

  @Input() sortableColumns: string[];
  @Input() scoreMedia: '';

  sortColumn;
  sortDirection;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  //localformConfig: DataModalForm = ObjectUtils.deepCopy(this.formConfig);
  private _localFormConfig: DataModalForm;
  constructor(
    public dialog: MatDialog,
    private _apiService: ApiService,
    private router: Router
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.edit && this.formConfig) {
      this.formConfig.edit = (changes.edit.currentValue && this.permissions['canEdit']);
      this._resetConfig();
    }
  }
  ngOnInit() {
    this._resetConfig();
    // this.formConfig = this.formConfig || {
    //   formControls: [],
    //   modalTitle: null,
    //   endpoint: null,
    //   id: null,
    //   edit: false
    // };
    this.formConfig.edit = (this.edit && this.permissions['canEdit']);
    if (!this.listTitle || !this.data || !this.formConfig) {
      throw new Error('faltan datos de entrada');
    }
    this.columns = Object.keys(this.displayedColumns);
    this.sortColumn = this.sortableColumns[0];
    this.sortDirection = 'asc';
    this.list = new TableDataSource(of({objects: this.data}), null, this.sort);
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(data => this.changeSort(data));
    this.sort.direction = this.sortDirection;
    this.sort.disableClear = true;
    ArraySort.orderByArray(this.data, this.sortColumn, this.sortDirection);
    this.list = new TableDataSource(of({objects: this.data}), null, this.sort);
  }
  navigateDetail(data) {
    this._updateValue(data);
    this._showModal();
    this._localFormConfig.id = data.id;
  }
  navigateTo(url) {
    window.open(url, "_blank");
  }
  newItem() {
    if (!this.edit) {
      return;
    }
    this._localFormConfig.id = null;
    this._showModal();
  }
  private _showModal() {
    const dialogRef = this.dialog.open(ModalFormComponent, {
      data: this._localFormConfig
    });

    dialogRef.afterClosed().subscribe(result => {
      this._resetConfig();
      if (result instanceof Object) {
        for (let index = 0; index < this.data.length; index++) {
          const element = this.data[index];
          if (element && element.id == result.id) {
            this.data.splice(index, 1);
          }
        }
        this.data.push(result);
        ArraySort.orderByArray(this.data, this.sortColumn, this.sortDirection );
        this.list = new TableDataSource(of({objects: this.data}), null, this.sort);
        //this.list = new TableDataSource(of({objects: this.data}));
      }
    });
  }
  private _updateValue(data) {
    this._localFormConfig.formControls.forEach(control => {
      if (data.hasOwnProperty(control.name)) {
        control.value = data[control.name];
      }
    });
  }

  confirmDelete(id: string|number, event: Event): void {
    if (!this.edit) {
      event.stopPropagation();
      return;
    }
    event.stopPropagation();
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: { title: 'Eliminar', text: '¿Está seguro que desea eliminar este registro?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'delete') {
        this.deleteConfig(id);
      }
    });
  }

  deleteConfig(id: string|number) {
    const api = this._apiService.getResourceService(this._localFormConfig.endpoint);
    api.delete(id).subscribe(
      success => {
        for (let index = 0; index < this.data.length; index++) {
          const element = this.data[index];
          if (element && element.id == id) {

            this.data.splice(index, 1);
          }
        }
        this.list = new TableDataSource(of({objects: this.data}));

      }
    );
  }
  changeSort(e) {
    this.sortColumn = this.sortableColumns[this.columns.indexOf(e.active)];
    this.sortDirection = e.direction;
    ArraySort.orderByArray(this.data, e.active,  e.direction);
    this.list = new TableDataSource(of({objects: this.data}), null, this.sort);
  }
  private _resetConfig() {
    this._localFormConfig = ObjectUtils.deepCopy(this.formConfig);
  }
}
