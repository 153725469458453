import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

export class ApiResource {
  private _urlBase: string;
  private _token: string;
  private _lang: string;

  constructor(
    apiBase: string,
    resourceName: string,
    private http: HttpClient,
    token: string,
    private router: Router,
    private appCommunicationService: any,
    lang = 'es'
  ) {
    this._urlBase = apiBase + '/' + resourceName + '/';
    this._token = token;
    this._lang = lang;
  }

  get(id: number|string, params?: HttpParams): Observable<any> {
    return this.http.get(`${this._urlBase}/${id}/`, { params, headers: this._getHeaders() })
      .pipe(
        catchError((err: any) => this.handleError(err, this.router))
      );
  }

  getFile(id: number|string, params?: HttpParams): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this._urlBase}/${id}/`, {
      params,
      headers: this._getHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      catchError((err: any) => this.handleError(err, this.router))
    );
  }

  schema(): Observable<any> {
    return this.http.get(`${this._urlBase}/schema`, { headers: this._getHeaders() });
  }

  delete(id: number|string, params?: HttpParams): Observable<any> {
    return this.http.delete(`${this._urlBase}/${id}/`, { params, headers: this._getHeaders() })
      .pipe(
        catchError((err: any) => this.handleError(err, this.router))
      );
  }

  list(params?: HttpParams): Observable<any> {
    return this.http.get(this._urlBase, { params, headers: this._getHeaders() })
      .pipe(
        catchError((err: any) => this.handleError(err, this.router))
      );
  }

  listFilter(params?: string): Observable<any> {
    return this.http.get(this._urlBase + params, { headers: this._getHeaders() })
      .pipe(
        catchError((err: any) => this.handleError(err, this.router))
      );
  }

  listPaginacion(url: string): Observable<any> {
    return this.http.get(url, { headers: this._getHeaders() })
      .pipe(
        catchError((err: any) => this.handleError(err, this.router))
      );
  }

  add(object: any, params?: HttpParams): Observable<any> {
    return this.http.post(this._urlBase + '/', object, { params, headers: this._getHeaders() })
      .pipe(
        catchError((err: any) => this.handleError(err, this.router))
      );
  }

  uploadAdjuntos(data: any, file: Blob, method: string): Observable<any> {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    if (file) {
      formData.append('document', file);
    }

    return this.http[method](`${this._urlBase}/`, formData, { headers: this._getHeaders() })
      .pipe(
        catchError((err: any) => this.handleError(err, this.router))
      );
  }

  uploadPhoto(data: any, file: Blob, method: string): Observable<any> {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    if (file) {
      formData.append('photo', file);
    }

    return this.http[method](`${this._urlBase}/`, formData, { headers: this._getHeaders() })
      .pipe(
        catchError((err: any) => this.handleError(err, this.router))
      );
  }

  update(object: any, id: number|string, params?: HttpParams): Observable<any> {
    return this.http.patch(`${this._urlBase}/${id}/`, object, { params, headers: this._getHeaders() })
      .pipe(
        catchError((err: any) => this.handleError(err, this.router))
      );
  }

  private _getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'authorization': sessionStorage.getItem('token'),
      'Accept-Language': this._lang,
      'Content-Language': this._lang
    });
  }

  handleError(error: any, router: Router): Observable<never> {
    switch (error.status) {
      case 401:
        this._token = null;
        sessionStorage.clear();
        router.navigateByUrl('/login');
        break;
      case 404:
        this._token = null;
        sessionStorage.clear();
        router.navigateByUrl('/403');
        break;
      case 403:
        this.appCommunicationService.sendSnackErrorMessage('No tiene autorización para visualizar este contenido');
        router.navigateByUrl('/teacher-portal/teacher');
        break;
      default:
        let errorMessage = 'Error desconocido';
        if (error.error instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const errorObj = JSON.parse(reader.result as string);
              errorMessage = errorObj.error_message || errorObj.error || JSON.stringify(errorObj);
              this.appCommunicationService.sendSnackErrorMessage(errorMessage);
            } catch (e) {
              this.appCommunicationService.sendSnackErrorMessage(errorMessage);
            }
          };
          reader.readAsText(error.error);
        } else {
          errorMessage = (error.error && error.error.error_message) || 
                        (error.error && error.error.error) || 
                        JSON.stringify(error.error);
          this.appCommunicationService.sendSnackErrorMessage(errorMessage);
        }
        break;
    }
    return throwError(error);
  }
}
