<div *ngIf="data" class="modal">
  <form *ngIf="!isPreview && formGroup" role="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div cdkFocusInitial tabindex="-1" class="header">

      <h1 mat-dialog-title>{{data.modalTitle}}</h1>
      <button type="button" (click)="onCancel()" mat-dialog-close mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-dialog-content>
      <ng-container *ngFor="let formField of data?.formControls">
        <ng-container *ngIf="['text', 'email', 'number', 'time'].indexOf(formField.type)!== -1">
          <mat-form-field appearance="outline">
            <mat-label>{{formField.title}}</mat-label>
            <input matInput [value]="formField.value" [formControlName]="formField.name" [type]="formField.type" [readonly]="formField.readonly || !data.edit && data.id">
            <mat-error *ngIf="formField.required && formGroup.get(formField.name).hasError('required')">
              {{errors.required}}
            </mat-error>
            <mat-error *ngIf="formField.minValue && formGroup.get(formField.name).hasError('min')">
              Nota mínima {{formField.minValue}}
            </mat-error>
            <mat-error *ngIf="formField.maxValue && formGroup.get(formField.name).hasError('max')">
              Nota máxima {{formField.maxValue}}
            </mat-error>
            <mat-error *ngIf="formField.type === 'email' && formGroup.get(formField.name).hasError('email') && !formGroup.get(formField.name).hasError('required')">
              {{errors.invalidEmail}}
            </mat-error>
          </mat-form-field>
        </ng-container>
        <div *ngIf="formField.type === 'ckeditor'" class="ckeditor-container">
          <fieldset>
            <legend>{{formField.title}}</legend>
            <ckeditor
              [editor]="Editor"
              [name]="formField.name"
              [formControlName]="formField.name"
              [data]="formField.value || ''"
              [config]="editorConfig"
            ></ckeditor>  
          </fieldset>
        </div>
        <ng-container *ngIf="formField.type === 'area'">
          <mat-form-field appearance="outline">
            <mat-label>{{formField.title}}</mat-label>
            <textarea matInput [value]="formField.value" [formControlName]="formField.name" matTextareaAutosize matAutosizeMinRows="3"
              matAutosizeMaxRows="10" [readonly]="formField.readonly || !data.edit && data.id"></textarea>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="formField.type === 'checkbox'">
          <div class="input-checkbox">
            <input type="checkbox" [formControlName]="formField.name" [disabled]="formField.readonly || !data.edit && data.id">{{formField.title}}
          </div>
        </ng-container>
        <ng-container *ngIf="formField.type === 'date'">
          <div class="full-width">
            <ad-calendar
              [formGroup]="formGroup"
              [control]="formField.name"
              [placeholder]="formField.title"
              [value]="formGroup.get(formField.name).value"
              [required]="formField.required"
              [disabled]="!data.edit && data.id"
            ></ad-calendar>
          </div>
        </ng-container>
        <ng-container *ngIf="formField.type === 'select'">
          <mat-form-field appearance="outline">
            <mat-label>{{formField.title}}</mat-label>
            <mat-select [formControlName]="formField.name" [disabled]="formField.readonly || !data.edit && data.id" (selectionChange)="selectChange($event,formField.name)">
              <mat-option *ngFor="let item of formField.selectData.itemList" [value]="item.id |fixSelect: formField.selectData.itemList">
                {{ item[formField.selectData.propertyName] }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formField.required && formGroup.get(formField.name).hasError('required')">
              {{errors.required}}
            </mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="formField.type === 'autocomplete'">
          <mat-form-field appearance="outline">
            <mat-label>{{formField.title}}</mat-label>
            <input matInput [attr.aria-label]="formField.title" (input)="autoCompleteText($event, formField.name)" [matAutocomplete]="auto" [formControlName]="'selected'+formField.name" [readonly]="formField.readonly || !data.edit && data.id">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectDone($event, formField.name)" [displayWith]="displayFn[formField.name]">
              <mat-option *ngFor="let item of filteredLists[formField.name] | async" [value]="item">
                <span>{{ item[formField.selectData.propertyName] }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </ng-container>
      </ng-container>
      <!-- Mensaje -->
      <ng-container *ngIf="data.message">
        <div class="message-container">
          <mat-icon aria-hidden="false" aria-label="Info icon">info_outline</mat-icon>
          <span class="info-msg">{{ data.message }}</span>
        </div>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button *ngIf="data.edit || !data.id" mat-button color="primary" type="button" (click)="onCancel()">Cancelar</button>
      <button *ngIf="!data.edit && data.id" (click)="onCancel()" type="button" mat-button color="primary">Volver</button>
      <button *ngIf="data.title_button && (data.edit || !data.id)"  mat-button color="primary" type="submit" [disabled]="!formGroup.valid">{{data.title_button}}</button>
      <button *ngIf="!data.title_button && (data.edit || !data.id)" mat-button color="primary" type="submit" [disabled]="!formGroup.valid">Guardar</button>

      <!-- <button *ngIf="data.edit || !data.id" mat-button color="primary" (click)="onCancel()">Cancelar</button>
      <button *ngIf="data.edit || !data.id" type="submit" mat-button color="primary" [disabled]="!formGroup.valid">Guardar</button>

      <button *ngIf="!edit && data.id" (click)="onCancel()" mat-button color="primary">Volver</button>
      <button *ngIf="!edit && data.id" (click)="edit = true" mat-button color="accent">Editar</button> -->
    </mat-dialog-actions>
  </form>
  <!--Previa del documento-->
  <div *ngIf="isPreview">
    <div class="header">
      <h1 mat-dialog-title>{{data.modalTitle}}</h1>
      <button type="button" (click)="onCancel()" mat-dialog-close mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-dialog-content>
      <div *ngIf="pdfSrc" class="iframe-wrapper">
        <!-- <iframe [src]="pdfSrc" width="100%" height="600px" style="border: none;"></iframe> -->
        <div *ngIf="fileExtension && fileExtension === 'pdf'">
          <iframe [src]="pdfSrc" width="100%" height="600px" style="border: none;"></iframe>
        </div>
      
        <!-- Template alternativo para imágenes -->
        <div *ngIf="fileExtension && fileExtension !== 'pdf'" class="container">
          <img 
            [src]="pdfSrc" 
            alt="Previsualización del documento" 
             />
        </div>
      </div>
      <div *ngIf="!pdfSrc" class="container">
        Cargando...
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button *ngIf="data.edit || !data.id" mat-button color="primary" type="button" (click)="onCancel()">Cancelar</button>
      <button *ngIf="!data.edit && data.id" (click)="onCancel()" type="button" mat-button color="primary">Volver</button>
      <button *ngIf="data.edit || !data.id" mat-button color="primary" type="button" (click)="downloadDocument()">Descargar</button>
    </mat-dialog-actions>
  </div>
</div>
