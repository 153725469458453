export class FormUtils {

  static debuildForm(origin: object): object {
    const group = {};
    for (const key in origin) {
      if (origin.hasOwnProperty(key)) {
        const element = origin[key];
        if (typeof element === 'string' || typeof element === 'number' || typeof element === 'boolean' ) {
          group[key] = origin[key];
        }
      }
    }
    return group;
  }
  public static filterAutoComplete(string: string, list: object[], field: string) {
    const localList: object[] = [].concat(list);
    if (typeof string !== 'string') {
      return;
    }
    return localList.filter(item =>
      item[field] && item[field].toLowerCase().indexOf(string.toLowerCase()) === 0);
  }
  public static filterAutoCompleteSearch(string: string, list: object[], field: string) {
    const localList: object[] = [].concat(list);
    if (typeof string !== 'string') {
      return;
    }
    return localList.filter(item =>
      item[field] && item[field].toLowerCase().indexOf(string.toLowerCase()) !== -1);
  }
  public static displayFn(val: any, field = 'name') {
    return val && val[field] ? val[field] : '';
  }
  public static getItemById(id: string, list: object[]): object {
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element['id'] == id) {
        return element;
      }
    }
  }
  public static isDuplicate(object, list: any[], id: string = 'id'): boolean {
    if (object && list && list.length > 0) {
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if (object[id] == element[id]) {
          return true;
        }
      }
    }
  }
}
