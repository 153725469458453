<div class="modal">
  <div class="header">

    <h1 mat-dialog-title>{{ data.title }}</h1>
    <button type="button" mat-dialog-close mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <p [innerHTML]="data.text"></p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-button color="primary" (click)="onCancel()">Cancelar</button>
    <mat-dialog-content *ngIf="data.title_button">
      <button mat-button mat-button color="primary" (click)="onDelete()">{{ data.title_button }}</button>
    </mat-dialog-content>
    <mat-dialog-content *ngIf="!data.title_button">
      <button mat-button mat-button color="warn" (click)="onDelete()">Borrar</button>
    </mat-dialog-content>

  </mat-dialog-actions>
</div>
