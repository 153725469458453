import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class TableDataSource extends DataSource<any> {
  private _data: Observable<any>;
  public list: object[];
  public fieldsMap = {};

  public constructor(source, private _paginator?: any, private _sort?: MatSort) {
    super();
    this._setData(source);
  }

  private _setData(source: Observable<any>) {
    if (this._paginator) {
      this._paginator.class = 'mat-paginator-disabled-button';
    }
    this._data = source.pipe(
      map((data) => {
        if (this._paginator) {
          this._paginator.length = data.meta.total_count;
          this._paginator.pageIndex = this._getPage(data.meta.offset);
          this._paginator.pageSize = data.meta.limit;
          this._paginator.next = data.meta.next;
          this._paginator.previous = data.meta.previous;
        }
        this.list = data.objects;
        if (this._paginator) {
          this._paginator.class = '';
        }
        if (this.list != null ) {
          if (!data.fields_map) {
            // tslint:disable-next-line:forin
            for (const key in this.list[0]) {
              this.fieldsMap[key] = key;
            }
          } else {
            this.fieldsMap = data.fields_map;
          }
        }
        return data.objects;
      })
    )
  }
  get paginator() {
    return this._paginator;
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  public connect(): Observable<Element[]> {
    return this._data;
  }

  public disconnect() {}
  _getPage(num: number) {
    return num === 0 ? 0 : num / 20;
  }

}
