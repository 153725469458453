import { FixSelectPipe } from '../fix-select/fix-select.pipe';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {StudentRegistrationComponent} from '../../gestor-academico/program/student-registration/student-registration.component';
import {EventAttendeeComponent} from '../../gestor-academico/events/event-attendee/event-attendee.component';
import {Data, Router, ActivatedRoute, Event} from '@angular/router';
import {ApiService} from '../../core/api/api.service';
import { Component, Input, OnInit, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Texts } from '../../gestor-academico/classes/texts';
import { FormUtils } from '../../gestor-academico/classes/form-utils';
import { DeleteConfirmDialogComponent } from '../delete-confirm-dialog/delete-confirm-dialog.component';
import { emptyData } from '../../gestor-academico/houses/houses-form/houses-form.component';
import { emptyDataValuation } from '../../gestor-academico/houses/houses-form/houses-form.component';
import { ObjectUtils } from '../../gestor-academico/classes/object-utils';
import { map } from 'rxjs/operators';
export class ScoreDataModalForm {
  data: {
    formControls: ModalFormField[];
    modalTitle: string;
    endpoint: string;
    message?: string;};
  dataValuationConfig: {
    formControls: ModalFormField[];
    modalTitle: string;
    endpoint: string;
    message?: string;
  };
  dataScore: emptyData [];
  dataValuation: emptyDataValuation [];
  id?: string|number;
  valuationId?: string|number;
  idRegistros?: string|number;
  edit?: boolean;
  emptData: emptyData[] = [{
    id: null,
    comments: '',
    house_id: 0,
    score: '0',
    score_type_id: '0',
    score_type_name: '',
  }]; 
}

export class ModalFormField {
  /**
   * Nombre del campo a enviar al API
   *
   * @type {string}
   * @memberof ModalFormField
   */
  name: string;
  /**
   * Titulo del campo
   *
   * @type {string}
   * @memberof ModalFormField
   */
  title: string;
  /**
   * Valor por defecto
   *
   * @type {*}
   * @memberof ModalFormField
   */
  value: any;
  /**
   * Tipo de elemento de formulario ('text', 'email', 'number', 'date', 'select')
   *
   * @type {string}
   * @memberof ModalFormField
   */
  type: string;
  /**
   * Es requerido?
   *
   * @type {Boolean}
   * @memberof ModalFormField
   */
  required?: Boolean;
  /**
   * Mínimo
   *
   * @type {number}
   * @memberof ModalFormField
   */
  minValue?: number;
  /**
   * Máximo
   *
   * @type {number}
   * @memberof ModalFormField
   */
  maxValue?: number;
  /**
   * Tipo de autocomplete ('startswith','contains')
   *
   * @type {string}
   * @memberof ModalFormField
   */
  autoCompleteType?: string;
  /**
   * Datos extra necesario para el select
   *
   * @type {SelectData}
   * @memberof ModalFormField
   */
  selectData?: SelectData;
}
export class SelectData {
  /**
   * Nombre de la propiedad a mostrar en el select
   *
   * @type {string}
   * @memberof SelectData
   */
  propertyName: string;
  /**
   * Lista de elementos del select
   *
   * @type {any[]}
   * @memberof SelectData
   */
  itemList: any[];
  /**
   * Función a llamar tras seleccionar un elemento de la lista
   */
  customUrlFunction?: CustomUrlFunction;
}
export interface CustomUrlFunction {
  change: Function;
}


@Component({
  selector: 'ad-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})

export class ScoreComponent implements OnInit {
  valueModal: any[] = [];
  valuationModal: any[] = [];
  realValueModal: any[] = [];
  cancelRealValueModal: any[] = [];
  reset: any[] = [];
  formGroup: UntypedFormGroup;
  modalTitle: string;
  errors = Texts.errors;
  // displayFn = FormUtils.displayFn;
  displayFn: any = {};
  filteredLists: any = {};
  selectedAutocomplete: any = {};
  stars: string[] = ['1', '2', '3', '4', '5'];
  valuations: string[] = ['1', '2', '3'];
  //negatives: string[] = ['-1', '-2', '-3'];
  selectedValue: number;
  sumatoria: number = 0;
  media: number;
  isTrue:  boolean = true;
  deleteScore:  boolean;
  emptData: emptyData[];
  api: any = {};
  addComment: string;

  constructor(
    public dialogRef: MatDialogRef<ScoreComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ScoreDataModalForm,
    public dialog: MatDialog,
    private _fb: UntypedFormBuilder,
    private _apiService: ApiService,
    private fixSelectPipe: FixSelectPipe
  ) { }

  ngOnInit() {
    this.deleteScore = false;
    this.reset = ObjectUtils.deepCopy(this.data.dataScore);
    this._buildForm(this.data.dataValuationConfig.formControls);
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
  }
  
  onClickStars(scores, stars) {
    scores.score = stars;
    this.valueModal.push(scores);
    this.valueModal = this.valueModal.reduce ((a,b) => {
      if (!a.find(data => data.score_type_id === b.score_type_id)) {
        a.push(b);
      }
      return a;
    }, []);
  }
  onClickPositive (valuation, positive) {
    valuation.positive = positive;
    this.valuationModal.push(valuation);
    this.valuationModal = this.valuationModal.reduce ((a,b) => {
      if (!a.find(data => data.score_type_id === b.score_type_id)) {
        a.push(b);
      }
      return a;
    }, []);  
  }

  onClickNegative (valuation, negative) {
    valuation.negative = negative;
    this.valuationModal.push(valuation);
    this.valuationModal = this.valuationModal.reduce ((a,b) => {
      if (!a.find(data => data.id === b.id)) {
        a.push(b);
      }
      return a;
    }, []);
  }

  onSubmit(){
    if (this.formGroup.valid) {
      const formData = FormUtils.debuildForm(this.formGroup.value);
      this.addComment = this.formGroup.get('comments').value;
      for (let valuation of this.data.dataValuation) {
        valuation.comments = this.addComment;
        if (this.data.valuationId) {
          this._apiService.getResourceService(this.data.dataValuationConfig.endpoint).update(
            valuation, this.data.valuationId
          ).subscribe(
            (res) =>  {console.log(res);}
          );
        }else {
          this._apiService.getResourceService(this.data.dataValuationConfig.endpoint).add(
            valuation
          ).subscribe(
            (res) => {console.log(res)}
          );
        }  
      }
    }
    this.onSave ();
  }

  onSave (){
    for (let valuation of this.valuationModal) {
      if (valuation.id) {
        console.log(this._apiService.getResourceService('houseValuation').update(valuation, valuation.id))
        this._apiService.getResourceService('houseValuation').update(valuation, valuation.id
          ).subscribe(
            (res) =>  {console.log(res)}
          );
      } else {
        console.log(this._apiService.getResourceService('houseValuation').add(this.valuationModal))
        this._apiService.getResourceService('houseValuation').add(valuation
          ).subscribe(
            (res) =>  {console.log(res)}                      
          );      
      }
    }
    if (this.valuationModal.length == 0 && this.valueModal.length !== 0){
      for( let datavaluation of  this.data.dataValuation){
        this._apiService.getResourceService('houseValuation').update(datavaluation, datavaluation.id
          ).subscribe(
            (res) =>  {console.log(res)}
          );        
      }
    }
    for (let score of this.valueModal) {
      if (score.id) {
        console.log(this._apiService.getResourceService(this.data.data.endpoint).update(score, score.id))
          this._apiService.getResourceService(this.data.data.endpoint).update(score, score.id
            ).subscribe(
              (res) =>  {console.log(res);
                this.dialogRef.close(res)
              }
            );
      } else {
        console.log(this._apiService.getResourceService(this.data.data.endpoint).add(score))
          this._apiService.getResourceService(this.data.data.endpoint).add(score
            ).subscribe(
              (res) =>  {console.log(res);
                this.dialogRef.close(res)}
            );
      }
    } 
    if (this.valueModal.length == 0) {
      this.dialogRef.close();
    }   
}   

  confirmDelete(id: string|number, score) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: { title: 'Eliminar', text: '¿Está seguro que desea eliminar este registro?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'delete') {
        this.deleteConfig(id, score);
      }
    });
  }

  deleteConfig(id: string|number, score) {
    this.deleteScore = true
    score.score = "0";
    const apiScore = this._apiService.getResourceService(this.data.data.endpoint);
    apiScore.delete(id).subscribe(
      success => {
        for (let index = 0; index < this.data.dataScore.length; index++) {
          const element = this.data.dataScore[index];
          if (element && element.id == id) {
            element.id = null;
            element.score  = "0";
          }
        }
      }
    );  
  }

  public onCancel() { 
    let res = true;
    this.dialogRef.close(res);
  }

  private _buildForm(formList: ModalFormField[]) {
    const group = {};
    formList.forEach(item => {
      const validators = [];
      if (item.type === 'select' && !item.selectData) {
        throw new Error(`${item.name} debe contener la propiedad select de tipo SelectData`);
      }
      if (item.title && item.required) {
        validators.push(Validators.required);
      }
      if (item.type === 'email' && item.required) {
        validators.push(Validators.email);
      }
      if (item.title && ('minValue' in item)) {
        validators.push(Validators.min(item.minValue));
      }
      if (item.title &&  ('maxValue' in item)) {
        validators.push(Validators.max(item.maxValue));
      }
      if (item.type === 'select' || item.type === 'autocomplete') {
        if (item.value !== null) {
          item.value = this.fixSelectPipe.transform(item.value, item.selectData.itemList);
        }
      }
      if (item.type === 'autocomplete') {
        const temp = item.selectData.itemList.find(data => data.id === item.value);
        group[`selected${item.name}`] = temp || {};
        this.displayFn[item.name] = (val) => FormUtils.displayFn(val, item.selectData.propertyName);
      }
      group[item.name] = [item.value , validators ];
    });
    //this.formGroup = this._fb.group(group);
    this.formGroup = this._fb.group({
      comments: ''
    });
    if (this.data.dataValuation[0] == undefined){
      return;
    } else {
        this.formGroup.get('comments').setValue(this.data.dataValuation[0].comments);
    }

    formList.forEach(item => {
      if (item.type === 'autocomplete') {

        this.filteredLists[item.name] = [];
        const tempList = [].concat(item.selectData.itemList);
        if (item.autoCompleteType ){
          if (item.autoCompleteType === 'startswith') {
            this.filteredLists[item.name] = this.formGroup.get(`selected${item.name}`).valueChanges
            .pipe(
              map(listItem => listItem ? FormUtils.filterAutoComplete(listItem, tempList,  item.selectData.propertyName) : tempList.slice())
            )
          }
          if (item.autoCompleteType === 'contains') {
            this.filteredLists[item.name] = this.formGroup.get(`selected${item.name}`).valueChanges
            // tslint:disable-next-line:max-line-length
            .pipe(
              map(listItem => listItem ? FormUtils.filterAutoCompleteSearch(listItem, tempList,  item.selectData.propertyName) : tempList.slice())
            )
          }
        } else {
          this.filteredLists[item.name] = this.formGroup.get(`selected${item.name}`).valueChanges
          .pipe(
            map(listItem => listItem ? FormUtils.filterAutoComplete(listItem, tempList,  item.selectData.propertyName) : tempList.slice())
          )
        }

        // this.filteredLists[item.name] = this.formGroup.get(`selected${item.name}`).valueChanges

        // // tslint:disable-next-line:max-line-length
        // .map(listItem => listItem ? FormUtils.filterAutoComplete(listItem, tempList,  item.selectData.propertyName) : tempList.slice());
      }
    });
  }
}
