import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomPipeModule } from './custom-pipe/custom-pipe.module';

import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DeleteConfirmDialogComponent } from './delete-confirm-dialog/delete-confirm-dialog.component';
import { CustomConfirmDialogComponent } from './custom-confirm-dialog/custom-confirm-dialog.component';
import { DynamicFormModule } from './dynamic-form/dynamic-form.module';
import { MatPaginatorIntlEsp } from '../gestor-academico/classes/matPaginatorIntlEspClass';
import { IterableModule } from './iterable/iterable.module';
import { CalendarModule } from './calendar/calendar.module';
import { ModalFormModule } from './modal-form/modal-form.module';
import { ModalFormComponent } from './modal-form/modal-form.component';
import { FormListModule } from './form-list/form-list.module';
import { ScoreModule } from './score/score.module';
import { FormAnnotationListModule } from './form-annotation-list/form-annotation-list.module';
import { FixSelectModule } from './fix-select/fix-select.module';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentFormComponent } from './documents/document-form/document-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ScoreComponent } from './score/score.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    DynamicFormModule,
    CommonModule,
    CustomPipeModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSlideToggleModule,
    IterableModule,
    CalendarModule,
    ModalFormModule,
    FormListModule,
    ScoreModule,
    FormAnnotationListModule,
    FixSelectModule,
    MatExpansionModule
  ],
  declarations: [
    DeleteConfirmDialogComponent,
    CustomConfirmDialogComponent,
    DocumentsComponent,
    DocumentFormComponent
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp}],
  exports: [
    DynamicFormModule,
    CustomPipeModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSlideToggleModule,
    IterableModule,
    CalendarModule,
    ModalFormModule,
    FormListModule,
    ScoreModule,
    FormAnnotationListModule,
    FixSelectModule,
    DocumentsComponent,
    MatExpansionModule
  ],
})
export class SharedModule { }
