import { HttpResponse } from '@angular/common/http';

export class FileUtils {
  private static typeMap: object = {
    pdf: 'application/pdf',
    jpg: 'image/jpg',
    png: 'image/png',
    jpeg: 'image/jpeg',
    zip: 'application/zip',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  };

  public static getMimeType(format: string) {
    return this.typeMap[format] || null;
  }

  public static getExtension(fileName: string) {
    const temp: string[] = fileName.split('.');
    const format = temp[temp.length - 1];
    return format ? format.toLocaleLowerCase() : null;
  }

  public static getFile(response: Blob | HttpResponse<Blob>, fileName: string) {
    if (!response || !fileName) {
      return;
    }

    const extension = this.getExtension(fileName);
    const mime = this.getMimeType(extension);
    let type = null;

    if (mime) {
      type = { type: mime };
    }

    const blob = response instanceof Blob ? response : new Blob([response.body], type);

    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  public static getValidFormats(): string[] {
    return Object.keys(this.typeMap);
  }

  public static fileIsValid(fileName: string): boolean {
    const ext = this.getExtension(fileName);
    return this.getValidFormats().indexOf(ext) !== -1;
  }

  public static getErrorMsg(): string {
    return `Los formatos admitidos son: ${this.getValidFormats().join()}`;
  }
}
