import { IterableModule } from './../iterable/iterable.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormListComponent } from './form-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalFormModule } from '../modal-form/modal-form.module';
import { MatSortModule } from '@angular/material/sort';
import { ScoreRatingComponent } from './score-rating/score-rating.component';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    IterableModule,
    MatButtonModule,
    MatDialogModule,
    ModalFormModule,
    MatSortModule
  ],
  declarations: [FormListComponent, ScoreRatingComponent],
  exports: [FormListComponent, ScoreRatingComponent]
})
export class FormListModule { }
