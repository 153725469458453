<div *ngIf="list">
  <fieldset>
    <legend>{{listTitle}}</legend>

    <mat-table #table [dataSource]="list" matSort matSortActive="{{sortColumn}}" matSortDirection="{{sortDirection}}">

      <ng-container *ngFor="let item of displayedColumns | iterable" >
        <ng-container *ngIf="item !== 'actions'" [matColumnDef]="item">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> {{displayedColumns[item]}} </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row[item] }}</mat-cell>
        </ng-container>

      </ng-container>
      <ng-container  *ngIf="columns && columns.indexOf('actions')!== -1" matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ displayedColumns.actions }} </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <a *ngIf="!exApi" (click)="navigateDetail(row)"><mat-icon>visibility</mat-icon></a>
          <!--<a *ngIf="exApi" (click)="navigateTo(row.url)" ><mat-icon>public</mat-icon></a>-->
          <ng-container *ngIf="permissions.canDelete">
              <ng-container *ngIf="row.canDeleteRecord!==undefined">
                  <a *ngIf="edit && row.canDeleteRecord" (click)="confirmDelete(row.id, $event)" ><mat-icon>delete_forever</mat-icon></a>
                  <a *ngIf="!edit || !row.canDeleteRecord" [ngClass]="{'disabled':true}"><mat-icon>delete_forever</mat-icon></a>
                  <!--<a [ngClass]="{'disabled':(!edit || !row.canDeleteRecord)}" (click)="confirmDelete(row.id, $event)" ><mat-icon>delete_forever</mat-icon></a>-->
              </ng-container>
              <ng-container *ngIf="row.canDeleteRecord===undefined">
                  <a [ngClass]="{'disabled':!edit}" (click)="confirmDelete(row.id, $event)" ><mat-icon>delete_forever</mat-icon></a>
              </ng-container>
          </ng-container>
        </mat-cell>º
      </ng-container>

      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
      <div *ngIf="!exApi">
        <mat-row [ngClass]="{'pointer':columns.indexOf('actions')!== -1}" (click)="navigateDetail(row)" *matRowDef="let row; columns: columns;"></mat-row>
      </div>
      <div *ngIf="exApi">
        <mat-row [ngClass]="{'pointer':columns.indexOf('actions')!== -1}" (click)="navigateTo(row.url)" *matRowDef="let row; columns: columns;"></mat-row>
      </div>
    </mat-table>
    
    <!-- Añadido Score Ratings -->
    <div *ngIf="score && score >= 0">
      <ad-score-rating
        [score] = score
      ></ad-score-rating>
    </div>

    <div class="button-group" >
      <button *ngIf="permissions.canAdd" type="button" mat-button color="primary" (click)="newItem()" [disabled]="!edit">{{ addText }}</button>
    </div>
  </fieldset>
</div>

