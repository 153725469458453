


<div *ngIf="data && formGroup" class="modal">
    <div class="header">
      <h1 mat-dialog-title>{{data.data.modalTitle}}</h1>
      <button type="button" (click)="onCancel()" mat-dialog-close = 'data?.dataScore' mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-dialog-content>
      <table class="default">  
      <ng-container *ngFor="let formField of data?.data.formControls">
        <ng-container *ngIf="formField.type === 'select'">
            <ng-container *ngFor="let item of formField.selectData.itemList">
                <ng-container *ngFor="let scoreData of data?.dataScore; index as i">
                  <ng-container *ngIf= "item.id == scoreData.score_type_id">                
                      <tr>
                        <div *ngIf="data.edit || !data.id" class="score-container activate">
                          <td>
                            <div class="score-type">
                              <span>{{ item[formField.selectData.propertyName] }}</span>
                            </div>  
                          </td>
                          <td>
                            <div class="list-inline rating-list" *ngFor="let star of stars" >
                                  <mat-icon (click)="onClickStars(scoreData, star)"
                                  [ngClass]="{'selected': (star <= scoreData.score && isTrue )}">star</mat-icon>
                            </div> 
                              <!-- botón eliminar -->
                              <a *ngIf="scoreData.score > 0 && scoreData.id != null" (click)="confirmDelete(scoreData.id, $event)" ><mat-icon>delete_forever</mat-icon></a>
                          </td>
                        </div> 
                        <div *ngIf="!data.edit && data.id" class="score-container deactivate">
                          <td>
                            <div class="score-type">
                              <span>{{ item[formField.selectData.propertyName] }}</span>
                            </div>  
                          </td>
                          <td>
                            <div class="list-inline rating-list" *ngFor="let star of stars" >
                              <mat-icon [ngClass]="{'deselected': (star <= scoreData.score && isTrue )}">star</mat-icon>
                            </div> 
                            <!-- botón eliminar -->
                            <a *ngIf="scoreData.score > 0 && scoreData.id != null" ><mat-icon>delete_forever</mat-icon></a>
                          </td>
                        </div>
                      </tr> 
                  </ng-container>
                </ng-container>
            </ng-container> 
        </ng-container>
      </ng-container>
      </table>
      <h3 mat-dialog-title>{{data.dataValuationConfig.modalTitle}}</h3>
      <table class="default"> 
        <ng-container *ngFor="let valuation of data?.dataValuation">
            <tr>
              <div *ngIf="data.edit || !data.id" class="score-container activate">
                <td>
                  <div class="score-type">
                    <span>Positivo</span>
                  </div>  
                </td>
                <td>
                  <!--<a *ngIf="valuation.positive > 0 && valuation.id != null" (click)="onClickPositive(valuation, 0)" ><mat-icon>delete_forever</mat-icon></a>-->
                  <a (click)="onClickPositive(valuation, 0)" ><mat-icon>cancel</mat-icon></a>
                  <div class="list-inline rating-list positive" *ngFor="let value of valuations" >
                    <mat-icon (click)="onClickPositive(valuation, value)"
                    [ngClass]="{'selected': (value <= valuation.positive && isTrue )}">add_circle</mat-icon>
                  </div> 
                </td>
              </div>

              <div *ngIf="!data.edit && data.id" class="score-container deactivate">
                <td>
                  <div class="score-type">
                    <span>Positivo</span>
                  </div>  
                </td>
                <td>
                  <!--<a *ngIf="valuation.positive > 0 && valuation.id != null" (click)="onClickPositive(valuation, 0)" ><mat-icon>delete_forever</mat-icon></a>-->
                  <a><mat-icon>cancel</mat-icon></a>
                  <div class="list-inline rating-list positive" *ngFor="let value of valuations" >
                    <mat-icon
                    [ngClass]="{'deselected': (value <= valuation.positive && isTrue )}">add_circle</mat-icon>
                  </div> 
                </td>
              </div>

            </tr>
            <tr>
              <div *ngIf="data.edit || !data.id" class="score-container activate">
                <td>
                  <div class="score-type">
                    <span>Negativo</span>
                  </div>  
                </td>
                <td>                
                  <a (click)="onClickNegative(valuation, 0)" ><mat-icon>cancel</mat-icon></a> 
                  <!--<a *ngIf="valuation.negative > 0 && valuation.id != null" (click)="onClickNegative(valuation, 0)" ><mat-icon>delete_forever</mat-icon></a>-->
                  <div class="list-inline rating-list negative" *ngFor="let value of valuations" >
                    <mat-icon (click)="onClickNegative(valuation, value)"
                    [ngClass]="{'selected': (value <= valuation.negative && isTrue )}">remove_circle</mat-icon>
                  </div>
                </td>
              </div>   

              <div *ngIf="!data.edit && data.id" class="score-container deactivate">
                <td>
                  <div class="score-type">
                    <span>Negativo</span>
                  </div>  
                </td>
                <td>                
                  <a><mat-icon>cancel</mat-icon></a> 
                  <!--<a *ngIf="valuation.negative > 0 && valuation.id != null" (click)="onClickNegative(valuation, 0)" ><mat-icon>delete_forever</mat-icon></a>-->
                  <div class="list-inline rating-list negative" *ngFor="let value of valuations" >
                    <mat-icon
                    [ngClass]="{'deselected': (value <= valuation.negative && isTrue )}">remove_circle</mat-icon>
                  </div>
                </td>
              </div>   

            </tr>
        </ng-container>
      </table>
  </mat-dialog-content>
  <form role="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <mat-form-field *ngIf="data.edit" class="score-container deactivate" appearance="outline" class="margen">
        <mat-label>Detalles</mat-label>
        <textarea matInput formControlName="comments" [readonly]="!edit && objeto" matTextareaAutosize matAutosizeMinRows="3" matAutosizeMaxRows="10"></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="!data.edit" class="score-container deactivate" appearance="outline" class="margen">
        <mat-label>Detalles</mat-label>
        <textarea matInput formControlName="comments" [readonly]="data.id" matTextareaAutosize matAutosizeMinRows="3" matAutosizeMaxRows="10"></textarea>
      </mat-form-field>
      <mat-dialog-actions>
        <button *ngIf="data.edit || !data.id" mat-button color="primary" type="button" (click)="onCancel()">Cancelar</button>
        <button *ngIf="!data.edit && data.id" (click)="onCancel()" type="button" mat-button color="primary">Volver</button>
        <button *ngIf="data.edit || !data.id" mat-button color="primary" type="button" (click)="onSubmit()">Guardar</button>
      </mat-dialog-actions>  
    </mat-dialog-content>

  </form>
</div>
