<div *ngIf="activeList || deleteList">
  <fieldset>
    <legend>{{listTitle}}</legend>

    <!-- Usuarios normales -->
    <ng-container *ngIf="!canList">
      <ng-container *ngIf="activeList">
        <mat-table #table [dataSource]="activeList" matSort>

          <ng-container *ngFor="let item of displayedColumns | iterable" >
            <ng-container *ngIf="item !== 'actions'" [matColumnDef]="item">
              <mat-header-cell *matHeaderCellDef  mat-sort-header> {{displayedColumns[item]}} </mat-header-cell>
              <mat-cell *matCellDef="let row">{{ row[item] }}</mat-cell>
            </ng-container>

          </ng-container>
          <ng-container  *ngIf="columns && columns.indexOf('actions')!== -1" matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> {{ displayedColumns.actions }} </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <a (click)="navigateDetail(row)"><mat-icon>visibility</mat-icon></a>
              <ng-container *ngIf="permissions.canDelete">
                <a [ngClass]="{'disabled':!edit}" (click)="confirmDelete(row.id, $event)" ><mat-icon>delete_forever</mat-icon></a>
              </ng-container>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row [ngClass]="{'pointer':columns.indexOf('actions')!== -1}" (click)="navigateDetail(row)" *matRowDef="let row; columns: columns;"></mat-row>
        </mat-table>
      </ng-container>

      <div class="button-group" >
        <button *ngIf="permissions.canAdd" type="button" mat-button color="primary" (click)="newItem()" [disabled]="!edit">{{ addText }}</button>
      </div>
    </ng-container>

    <!-- Usuarios con permisos -->
    <ng-container *ngIf="canList">
      <ng-container *ngIf="activeList">
        <mat-table #table [dataSource]="activeList" matSort>
          <ng-container *ngFor="let item of displayedAdminColumns | iterable" >
            <ng-container *ngIf="item !== 'actions'" [matColumnDef]="item">
              <mat-header-cell *matHeaderCellDef  mat-sort-header> {{displayedAdminColumns[item]}} </mat-header-cell>
              <mat-cell *matCellDef="let row">{{ row[item] }}</mat-cell>
            </ng-container>
          </ng-container>
          <ng-container  *ngIf="adminColumns && adminColumns.indexOf('actions')!== -1" matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> {{ displayedAdminColumns.actions }} </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <a (click)="navigateDetail(row)"><mat-icon>visibility</mat-icon></a>
              <ng-container *ngIf="permissions.canDelete">
                <a [ngClass]="{'disabled':!edit}" (click)="confirmDelete(row.id, $event)" ><mat-icon>delete_forever</mat-icon></a>
              </ng-container>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="adminColumns"></mat-header-row>
          <mat-row [ngClass]="{'pointer':adminColumns.indexOf('actions')!== -1}" (click)="navigateDetail(row)" *matRowDef="let row; columns: adminColumns;"></mat-row>
        </mat-table>
      </ng-container>

      <div class="button-group" >
        <button *ngIf="permissions.canAdd" type="button" mat-button color="primary" (click)="newItem()" [disabled]="!edit">{{ addText }}</button>
      </div>
  
      <ng-container *ngIf="showDeleteAnnotation">
        <div class="more-legends">Anotaciones borradas</div>
        <mat-table #table [dataSource]="deleteList" matSort class="without-last-border">
          <ng-container *ngFor="let item of displayedAdminColumns | iterable" >
            <ng-container *ngIf="item !== 'actions'" [matColumnDef]="item">
              <mat-header-cell *matHeaderCellDef  mat-sort-header> {{displayedAdminColumns[item]}} </mat-header-cell>
              <mat-cell *matCellDef="let row">{{ row[item] }}</mat-cell>
            </ng-container>
          </ng-container>
          <ng-container  *ngIf="adminColumns && adminColumns.indexOf('actions')!== -1" matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> {{ displayedAdminColumns.actions }} </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <a (click)="navigateDetail(row)"><mat-icon>visibility</mat-icon></a>
              <!-- <ng-container *ngIf="canDelete">
                <a [ngClass]="{'disabled':!edit}" (click)="confirmRealDelete(row.id, $event)" ><mat-icon>delete_forever</mat-icon></a>
              </ng-container> -->
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="adminColumns"></mat-header-row>
          <mat-row [ngClass]="{'pointer':adminColumns.indexOf('actions')!== -1}" (click)="navigateDetail(row)" *matRowDef="let row; columns: adminColumns;"></mat-row>
        </mat-table>
      </ng-container>
    </ng-container>
  </fieldset>
</div>

