import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Texts } from '../../gestor-academico/classes/texts';

function dateValidator(fechaString: string): boolean {
  const dateSplit: number[] = fechaString.split('/').map(dato => +dato);
  if (dateSplit.length < 3) {
    return false;
  }
  let dia: string = '' + dateSplit[0];
  if (dateSplit[0] < 10) {
    dia = '0' + dia;
  }

  let mes: string = '' + dateSplit[1];
  if (dateSplit[1] < 10) {
    mes = '0' + mes;
  }

  const fechaEstandarizada: string = dia + '/' + mes + '/' + dateSplit[2];

  return fechaEstandarizada === CalendarComponent.dateToString(CalendarComponent.stringToDate(fechaEstandarizada));
}

function dateValidatorControl(control: UntypedFormControl) {
  if (dateValidator(control.value)) {
    return null;
  } else {
    return {
      input: 'Fecha inválida'
    };
  }
}

@Component({
  selector: 'ad-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {
  @Input() formGroup: UntypedFormGroup;
  @Input() control: string;
  @Input() placeholder: string;
  @Input() value: string;
  @Input() required: boolean;
  @Input() disabled: boolean;

  errors = Texts.errors;
  internalForm: UntypedFormGroup;
  @ViewChild('inputString', { read: ElementRef }) inputString: ElementRef;

  static dateToString(date: Date): string {
    const dia: string =
      date.getDate() < 10 ? '0' + date.getDate() : '' + date.getDate();
    const mesDate = 1 + date.getMonth();
    const mes: string =
      mesDate < 10 ? '0' + mesDate : '' + mesDate;
    const anio: string = '' + date.getFullYear();
    return `${dia}/${mes}/${anio}`;
  }

  static stringToDate(datestr: string): Date {
    const dateSplit: number[] = datestr.split('/').map(dato => +dato);

    return new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
  }

  static stringToDateDatabase(datestr: string): string {
    let val = '';
    if (datestr !== '') {
      const dateSplit: string[] = datestr.split('/');
      val = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
    }
    return val;
  }

  constructor(private _formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    if (this.required) {
      this.internalForm = this._formBuilder.group({
        input: ['', Validators.required],
        inputString: ['', [dateValidatorControl, Validators.required]]
      });
    } else {
      this.internalForm = this._formBuilder.group({
        input: [''],
        inputString: ['', dateValidatorControl]
      });
    }
    this._setDate();
  }

  cambioFechaString(event) {
    const fechaString = this.inputString.nativeElement.value;

    if (!fechaString) {
      this.internalForm.get('input').setValue(null);
      this.formGroup.get(this.control).setValue(null);
      this.formGroup.get(this.control).updateValueAndValidity();
      return;
    }

    if (dateValidator(fechaString)) {
      this.internalForm.get('input').setValue(CalendarComponent.stringToDate(fechaString));
      this.formGroup.get(this.control).setValue(CalendarComponent.stringToDateDatabase(fechaString));
      this.formGroup.get(this.control).updateValueAndValidity();
    } else {
      this.internalForm.get('input').setValue(null);
    }
  }


  private _setDate() {
    if (this.value && this.internalForm) {
      const date = this.value.split('-');
      this.internalForm.get('input').setValue(new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2])));
      setTimeout(() => this.internalForm.get('inputString').setValue(date[2] + '/' + date[1] + '/' + date[0]));
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this._setDate();
  }
  addEvent(event: MatDatepickerInputEvent<Date>) {
    if (!event || !event.value) {
      this.formGroup.get(this.control).setValue(null);
      this.internalForm.get('inputString').setValue('');
      this.formGroup.get(this.control).updateValueAndValidity();
      return;
    }

    let day = event.value.getDate().toString();
    let month = (event.value.getMonth() + 1).toString();
    const year = event.value.getFullYear().toString();
    
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    
    const val = `${year}-${month}-${day}`;
    this.internalForm
      .get('inputString')
      .setValue(CalendarComponent.dateToString(event.value));
    
    this.formGroup.get(this.control).setValue(val);
    this.formGroup.get(this.control).updateValueAndValidity();
  }
}
