import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FixSelectPipe } from './fix-select.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FixSelectPipe],
  exports: [FixSelectPipe]
})
export class FixSelectModule { }
