export class ArraySort {
  public static orderByArray (values: any[], orderType: any, orderDirection: any) {
    if (orderDirection === 'asc'){
      return values.sort((a, b) => {
        if (a[orderType] < b[orderType]) {
            return -1;
        }
        if (a[orderType] > b[orderType]) {
            return 1;
        }
        return 0;
      });
    } else {
      return values.sort((a, b) => {
        if (a[orderType] > b[orderType]) {
            return -1;
        }
        if (a[orderType] < b[orderType]) {
            return 1;
        }
        return 0;
      });
    }
  }
}
