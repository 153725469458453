<div>
  <form (ngSubmit)="sendForm()" [formGroup]="form">

    <div *ngFor="let question of questions" class="form-row">
      <ad-df-question [question]="question" [form]="form"></ad-df-question>
    </div>

    <div class="form-row">

      <button mat-button color="primary" type="submit" [disabled]="!form.valid">Save</button>
    </div>
  </form>

  <div *ngIf="payLoad" class="form-row">
    <strong>Saved the following values</strong><br>{{payLoad}}
  </div>
</div>
